import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout/layout";
import Offer from "../components/Offer/offer";
import config from "../../data/SiteConfig";
import Card from "react-md/lib/Cards/Card";

class OfferPage extends Component {
  render() {
    return (
        <Layout location={this.props.location} title="Offer">
            <div className="offer-container">
            <Helmet>
                <title>{`Offer | Samsung Offer`}</title>
                <link rel="canonical" href={`${config.siteUrl}/Offer/`} />
            </Helmet>
            <Offer />
            </div>
        </Layout>
    );
  }
}

export default OfferPage;
